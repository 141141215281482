import {React, useState} from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./AboutUs.css";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import coverImage from '../gallery_pics/aboutus_cover.jpg'

const AboutUs = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <div className="about-us" id="about-us">
        <Row className="row">
          <Col md={6} className="image-stats">
            <img
              src={coverImage}
              alt="About Us"
              className="about-us-image"
            />
            <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
            <div className="stats">
              <div className="stat stat_round1">
                <h3>{counterOn && <CountUp start={0} end={50} duration={2} delay={0}/>}+</h3>
                <p>Projects</p>
              </div>
              <div className="stat">
                <h3>{counterOn &&<CountUp start={0} end={4} duration={2} delay={0}/>}k+</h3>
                <p>Beneficiaries</p>
              </div>
              <div className="stat">
                <h3>{counterOn &&<CountUp start={0} end={5} duration={2} delay={0}/>}k+</h3>
                <p>People Employed</p>
              </div>
              <div className="stat stat_round2">
                <h3>{counterOn &&<CountUp start={0} end={100} duration={2} delay={0}/>}+</h3>
                <p>Partnerships</p>
              </div>
            </div>
            </ScrollTrigger>
          </Col>
          <Col md={6} className="info-container">
          <h2 className="about">ABOUT US</h2>
            <h2 className="about-title">Our Work Promise To Uphold The Trust Placed</h2>
            <p>
            We are committed to positively impacting the communities we serve with integrity, transparency, and trust. We focus on creating opportunities, fostering growth, and supporting those in need with compassion and respect
            </p>
            <div className="list-experience-container">
              <ul>
                <li>
                  <i className="fas fa-check"></i> Providing quality education.
                </li>
                <li>
                  <i className="fas fa-check"></i> Collaborating with local organizations.
                </li>
                <li>
                  <i className="fas fa-check"></i> Ensuring transparency and accountability.
                </li>
                <li>
                  <i className="fas fa-check"></i> Empowering individuals.
                </li>
              </ul>
              <div className="experience">
                <h2>16</h2>
                <p>Years of Experience</p>
              </div>
            </div>
            {/* <Button variant="warning">Learn More</Button> */}
          </Col>
        </Row>
    </div>
  );
};

export default AboutUs;

// https://images.unsplash.com/photo-1509099836639-18ba1795216d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D

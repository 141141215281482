import React from "react";
import "./UpcomingEvents.css";
import treeImage from "../assets/tree.jpg";
import schoolImage from "../assets/school_pgm.jpg";
import Certificate from "../assets/cert_dist.jpg";

const UpcomingEvents = () => {
  return (
    <section className="events-section">
      <div className="container">
        <h3 className="section-subtitle1">UPCOMING EVENTS</h3>
        <h2 className="section-title">Come To Our Events For More Info</h2>
        <div className="events-grid">
          <div
            className="event-card"
            style={{ backgroundImage: `url(${treeImage})` }}
          >
            <div className="event-date">
              <span className="date">15th</span>
              <br></br>
              <span className="month">JUL</span>
            </div>
            <div className="event-details">
              <h4>Tree Plantation Campaign</h4>
              <div className="date-time">
                <p>
                  <i className="far fa-clock"></i> 8:00 am - 12:30 pm{" "}
                  <i className="fas fa-map-marker-alt"></i> Shahpur, Maharashtra
                </p>
              </div>
              <p>
              Aimed at fostering environmental stewardship, we're engaging communities in planting trees to enhance greenery and promote sustainability.
              </p>
              <div className="event-buttons">
                <button className="event-button">View Details</button>
              </div>
            </div>
          </div>
          <div
            className="event-card"
            style={{ backgroundImage: `url(${Certificate})` }}
          >
            <div className="event-date">
              <span className="date">21st</span>
              <br></br>
              <span className="month">AUG</span>
            </div>
            <div className="event-details">
              <h4>Certificate Distribution Camp</h4>
              <div className="date-time">
                <p>
                  <i className="far fa-clock"></i> 10:00 am - 2:30 pm{" "}
                  <i className="fas fa-map-marker-alt"></i> Asangaon, Maharashtra
                </p>
              </div>
              <p>
                Training will be provided to Asangaon grampanchayat women on how to run a business and certificates will be distributed.
              </p>
              <div className="event-buttons">
                <button className="event-button">View Details</button>
              </div>
            </div>
          </div>
          <div
            className="event-card"
            style={{ backgroundImage: `url(${schoolImage})` }}
          >
            <div className="event-date">
              <span className="date">1st</span>
              <br></br>
              <span className="month">OCT</span>
            </div>
            <div className="event-details">
              <h4>School Project Program</h4>
              <div className="date-time">
                <p>
                  <i className="far fa-clock"></i> 10:00 am - 2:30 pm{" "}
                  <i className="fas fa-map-marker-alt"></i> Shahpur, Maharashtra
                </p>
              </div>
              <p>
              Empowering rural students from 9th to 12th standard. We're enhancing education in Trust, Ashram, and ZP schools across Maharashtra.
              </p>
              <div className="event-buttons">
                <button className="event-button">View Details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
